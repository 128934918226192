<template>
    <v-container fluid>
        <template>
            <v-row class="px-0">
                <v-col  cols="12" class="mb-0 pb-0">
                    <h3 class="headline pb-2 text-center black--text">{{ $t('new_application')}}</h3>
                     <v-progress-linear :active="loading" indeterminate></v-progress-linear>

                    <v-skeleton-loader v-if="loading_skeleton"
                                       v-bind="attrs"
                                       type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
                <v-row class="px-0">
                <v-col cols="12" class="px-2" v-for="(item, index) in orderItems" :key="index">
                    <v-hover v-slot="{ hover }" open-delay="300">
                        <v-card class="mx-auto cursor-pointer" flat
                                :color="item.color"
                                :elevation="hover ? 16 : 2"
                                :class="{ 'on-hover': hover }"

                        >
                            <v-list-item three-line @click="showDetailsOrder(item)">
                                <v-list-item-content class="pb-0">
                                    <v-list-item-title class="text-h8 mb-1" :class="item.text_class">
                                        {{ item.client_name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle :class="item.text_class">{{ item.address }}</v-list-item-subtitle>
                                    <div class="text-overline small" :class="item.text_class">
                                        {{ item.on_date }} {{ item.on_time !== '00:00' ? item.on_time : '' }}
                                    </div>
                                </v-list-item-content>
                            </v-list-item>

                            <v-card-actions>
                                <v-btn
                                    :loading="item.loading"
                                    @click="acceptOrder(item)"
                                    width="100%"
                                    :outlined="item.text_class === 'white--text'"
                                    :color="item.text_class === 'white--text' ? 'white' : 'yellow' "
                                    :class="item.text_class"
                                >
                                    {{ $t('accept_application')}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-hover>
                </v-col>
           </v-row>

            <v-dialog v-if="dialogDetails" v-model="dialogDetails" fullscreen hide-overlay transition="dialog-bottom-transition">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn icon dark @click="cancelDetails()">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" :class="this.$vuetify.breakpoint.name !== 'xs' ? 'text-right' : 'pb-0'">
                                <v-col cols="12" class="py-1"><b> {{ $t('number_order')}}: </b>{{ detailsItems.number ? detailsItems.number :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('on_date_order')}}: </b>{{ detailsItems.on_date ?  $moment(detailsItems.on_date).format('DD.MM.YYYY') :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('on_time_order')}}: </b>{{ detailsItems.on_time ? detailsItems.on_time :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('order_type')}}: </b> {{ detailsItems.order_type ? detailsItems.order_type :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('important')}}: </b> {{ ( detailsItems.important ? (( detailsItems.important == 1 ) ? $t('yes'): $t('no')) :  $t('no') )  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('company')}}: </b> {{ detailsItems.company ? detailsItems.company :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('class_order')}}: </b> {{ detailsItems.class ? detailsItems.class :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('summa')}}: </b> {{ detailsItems.summa ? detailsItems.summa :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('nature_of_cargo')}}: </b> {{ detailsItems.nature_of_cargo ? detailsItems.nature_of_cargo :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('contract')}}: </b> {{ detailsItems.contract ? detailsItems.contract :  $t('no_data')  }}</v-col>
                            </v-col>
                            <v-col cols="12" sm="6" :class="this.$vuetify.breakpoint.name === 'xs' ? 'py-0' : ''">
                                <v-col cols="12" class="py-1"><b>{{ $t('state')}}: </b>{{ detailsItems.state ? detailsItems.state :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b> {{ $t('fence_address')}}:</b> {{ detailsItems.address ? detailsItems.address :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('payment_order')}}: </b>{{ detailsItems.payer ? detailsItems.payer :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('courier')}}: </b> {{ detailsItems.courier ? detailsItems.courier :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('is_cash')}}: </b> {{ ( detailsItems.is_cash ? (( detailsItems.is_cash == 1 ) ? $t('yes'): $t('no')) :  $t('no') )  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('reason_text')}}: </b> {{ detailsItems.reason_text ? detailsItems.reason_text :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('description_order')}}: </b> {{ detailsItems.description ? detailsItems.description :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('sender')}}: </b> {{ detailsItems.sender ? detailsItems.sender :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('account_client_name')}}: </b> {{ detailsItems.client_name ? detailsItems.client_name :  $t('no_data')  }}</v-col>
                                <v-col cols="12" class="py-1"><b>{{ $t('phone')}}: </b> {{ detailsItems.phone ? detailsItems.phone :  $t('no_data')  }}</v-col>
                            </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-row class="p-2 m-2">
                            <v-col cols="12" sm="6" v-for="(invoice, index) in detailsItems.invoices" :key="index">
                                <v-hover
                                    v-slot="{ hover }"
                                    open-delay="200"
                                >
                                    <v-card
                                        :elevation="hover ? 16 : 2"
                                        :class="{ 'on-hover': hover }"
                                    >
                                        <v-card-text>
                                            <p class="py-0 my-0"><b>{{ $t('number_invoice') }}:</b> {{ invoice.number }}</p>
                                            <p class="py-0 my-0"><b>{{ $t('name_recipient') }}:</b> {{ invoice.name }}</p>
                                            <p class="py-0 my-0"><b>{{ $t('phone_recipient') }}:</b> {{ invoice.phone }}</p>
                                            <p class="py-0 my-0"><b>{{ $t('address_recipient') }}:</b> {{ invoice.address }}</p>
                                            <p class="py-0 my-0"><b>{{ $t('weight') }}:</b> {{ invoice.weight }} {{ $t('kg') }}</p>
                                            <p class="py-0 my-0"><b>{{ $t('date') }}:</b>  {{  invoice.date }}</p>
                                            <!--<p class="py-0 my-0">{{ $t('title') }}: {{ invoice.uuid }}</p>-->
                                        </v-card-text>
                                    </v-card>
                                </v-hover>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn
                                    :loading="loading"
                                    @click="acceptOrder(detailsItems)"
                                    width="100%"
                                    color="primary"
                                >
                                    {{ $t('accept_application')}}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </template>
    </v-container>
</template>
<script>


import {mapGetters} from "vuex";

export default {
    name: "Orders",
    data() {
        return {
            loading: false,
            progress: 0,
            loading_skeleton: false,
            orderItems: [],
            detailsItems: [],
            dialogDetails: false,
            attrs: {
                class: 'mb-3',
                boilerplate: true,
                elevation: 2,
            },
        };
    },
    computed: {
        ...mapGetters(['lang']),
    },
    async mounted() {

        this.loading_skeleton = true
        await this.getOrders()
        await this.showOrders()
        this.loading_skeleton = false

    },
    methods: {
        async showOrders(){
            var _this = this
            if (this.$route.params.uuid) {
                let uuid = this.$route.params.uuid;
                this.orderItems.forEach(function(item){
                    if(item.uuid === uuid){
                        _this.detailsItems = item
                        _this.dialogDetails = true;
                        return;
                    }
                })
            }
        },
        async getOrders() {
            var _this = this
            this.loading = true;
            let params = {}


            if (this.lang) {
                params.language = this.lang
            }


            await this.$http
                .get("courier/order", {
                    params: params,
                })
                .then(res => {
                    this.orderItems = res.body.data

                })
                .catch(err => {
                    this.orderItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_orders'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async acceptOrder(item) {
            item.loading = true;
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            let order_uuid = item.uuid;
            if (this.language) {
                formData.append('language', this.language)
            }

            // Save
            await this.$http
                .put(`courier/order/${order_uuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('уou_have_accepted_application'))
                    let myIndex = this.orderItems.indexOf(item);
                    if (myIndex !== -1) {
                        this.orderItems.splice(myIndex, 1);
                        this.dialogDetails = false
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('application_acceptance_error'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                    item.loading = false;
                })


        },
        async showDetailsOrder(item) {
            this.loading = true;
            this.detailsItems = item
            this.dialogDetails = true;
            this.loading = false;
        },
        cancelDetails(){
            this.dialogDetails = false;
        },
    }
}
</script>
